import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {







  ispractice: boolean = true;
  venderId: any;
  IsTNQiFrame: boolean = false;
  IsAptaraFrame:boolean=false
IsMPSiFrame:boolean=false;
IsStriveiFrame:boolean=false;

IsLoader:boolean=true;

  constructor() { }


  ngOnInit(): void {
    this.venderId = sessionStorage.getItem('VendorId');

    if (this.venderId == 3) {
      this.IsTNQiFrame = true;
      this.IsLoader=false;
    }
    if (this.venderId == 4) {
      this.IsAptaraFrame = true;
      this.IsLoader=false;
    }
    if (this.venderId == 5) {
      this.IsMPSiFrame = true;
      this.IsLoader=false;
    }
    if (this.venderId == 9) {
      this.IsStriveiFrame = true;
      this.IsLoader=false;
    }
  }


  Check() {
    this.ispractice = false;
  }



}

